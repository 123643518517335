<template>
 <div class="home">
    <div class="tabledata">
      <el-table :data="tableData" border>
        <el-table-column prop="projectTitle" label="项目名称"> 
        </el-table-column>
        <!--
        <el-table-column prop="orderNum" label="订单数"> 
        </el-table-column>
        -->
        <el-table-column prop="violateNum" label="违规订单数"> 
        </el-table-column>
        <!--
        <el-table-column prop="violateRate" label="违规比例"> 
        </el-table-column>
        -->
        <el-table-column label="操作" align="center" width="380">
          <template slot-scope="scope">
              <span class="handel" @click="getLgViolateOrders(scope.row.projectId)">查看违规订单</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
</div>
</div>
</template>
<script>
import { getLgViolateStat } from "@/api/violateorder";
import router from '@/router';
  export default {
    data() {
      return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
      };
    },
  mounted() {
    this.search();
  },

    methods: {
      async search(val){
        await getLgViolateStat({
          pageNo: this.currentPage, 
          pageSize: this.pageSize
          }).then((res) => {
            if (res.code == 1000) {
      this.tableData = res.data.records;
      this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    },
    getLgViolateOrders(val) {
      router.push({ path: `/lgviolateorder/${val}` }) 
    }
    }
};
</script>

