/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:03
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-05-29 16:12:28
 */
import request from '@/plugins/axios'
const base = '/'


export function getViolateStat(params) {
    return request({
      url:  base + 'api/jdOrder/violate/stat',
      method: 'get',
      params
    })
  }

export function getLgViolateStat(params) {
return request({
    url:  base + 'api/jdOrder/violate/lg/stat',
    method: 'get',
    params
})
}

export function getViolateList(params) {
    return request({
        url:  base + 'api/jdOrder/violate/list',
        method: 'get',
        params
    })
}